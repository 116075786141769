import React from 'react'
import { Droppable } from 'react-beautiful-dnd'
import styled, { css } from 'styled-components'

import { PROCESS, DROP } from 'types'
import { ReactComponent as EditIcon } from 'static/icons/edit-icon.svg'
import { color } from 'components/zensmart-design-system/shared/styles.js'

import { SiblingArrow } from '../primaries'
import { BlockDetailsModal } from '../sections'
import { ReactComponent as CloseIcon } from 'static/icons/close.svg'

const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 8px;
`

const Title = styled.div`
  margin: 0;
  text-align: center;
`

const TextContainer = styled.div`
  position: relative;
  display: inline-block;
`

const getDirectionStylings = ({ flexDirection }) => css`
  flex-direction: ${flexDirection};

  ${flexDirection === 'column' && css`
    & > div > div[data-with-sibling-arrow="1"] {
      align-items: center;
      flex-direction: column-reverse;
      & > div[data-sibling-arrow] {
        height: 32px;

        &:before {
          border: none;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 12px solid ${color.nav.unselected};
        }
      }
    }
  `}
`

const Body = styled(Container)`
  ${getDirectionStylings}
  ${props => props.stylings}
`

const EditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
  margin-right: 3px;

  width: 16px;
  height: 16px;

  background-color: ${props => props.color};
  border-radius: 50%;

  cursor: pointer;
  transition-duration: 0.15s;
`

const DeleteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  background-color: ${props => props.color};
  border-radius: 50%;

  cursor: ${props => props.cursor || 'pointer'};
  transition-duration: 0.15s;
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-8px);
`

function ProcessContainer(props) {
  const { process, editArea, processID, children } = props

  const { dragType, modal, updateProcess, currentProductBlockTypes, setCurrentProductBlockTypes, onRemoveBarcode, blockTypesOptions, blockDefinition, setBlockDefinition, primaryGroupCode } = editArea

  const [currentModal, setModal] = modal

  const containerID = `<${DROP.ACCEPT}>${processID}`

  let direction = 'vertical'
  let flexDirection = 'column'
  let containerBodyStylings = null
  let closeModal = null

  /**
   * Give a subtle grey background color to the
   * block items container.
   */
  if (process.accepts.includes(PROCESS.TYPE.BLOCK)) {
    containerBodyStylings = css`
      background-color: #f8f8f8;
    `
  }

  if (process.type === PROCESS.TYPE.BLOCK) {
    direction = 'horizontal'
    flexDirection = 'row'
    containerBodyStylings = css`
      min-width: 256px;
      min-height: 80px;
      background-color: ${color.buttercup.light};
      padding: 24px 0px;

      & ~ ${Title} {
        background-color: ${color.buttercup.normal};
      }
    `

    closeModal = (evt) => {
      /**
       * Click events on the antd Modal component won't
       * work unless the event doesn't get propagated further.
       * 
       * More details on this link:
       * {@link https://github.com/ant-design/ant-design/issues/8738#issuecomment-354035083}
       **/
      if (evt) {
        evt.stopPropagation()
      }

      setModal(null)
    }
  }

  const removeBlock = () => {
    onRemoveBarcode(process)
    closeModal();
    const typeIndexRemoved = currentProductBlockTypes.indexOf(process.text);
    let blockTypes = [...currentProductBlockTypes];
    blockTypes.splice(typeIndexRemoved, 1);
    setCurrentProductBlockTypes(blockTypes);
    setBlockDefinition(blockDefinition.filter(data => data.blockType !== process.text))
  }

  return (
    <Container>
      <Wrapper>
        <Droppable
          type={process.accepts.includes(dragType) && dragType}
          droppableId={containerID}
          direction={direction}
        >
          {provided => (
            <Body
              {...provided.droppableProps}
              ref={provided.innerRef}
              stylings={containerBodyStylings}
              flexDirection={flexDirection}
            >
              {children}
              {provided.placeholder}
            </Body>
          )}
        </Droppable>

        {process.text && (
          <Title>
            <IconContainer>
              <EditContainer
                color={color.buttercup.dark}
                onClick={() => setModal(containerID)}
              >
                <EditIcon width="8px" fill="#fff" />
                <BlockDetailsModal
                  title={`Edit ${process.type}`}
                  visible={currentModal === containerID}
                  onCancel={closeModal}
                  footer={null}
                  processID={containerID}
                  process={process}
                  primaryGroupCode={primaryGroupCode ?? ''}
                  onSave={updateProcess}
                  width={1200}
                  currentProductBlockTypes={currentProductBlockTypes}
                  setCurrentProductBlockTypes={setCurrentProductBlockTypes}
                  blockTypesOptions={blockTypesOptions}
                  blockDefinition={blockDefinition}
                  setBlockDefinition={setBlockDefinition}
                />
              </EditContainer>
              {process.text.toLowerCase() !== "text" ?

                <DeleteContainer
                  color={color.red}
                  onClick={removeBlock}
                >
                  <CloseIcon
                    width="16px"
                    height="16px"
                    fill="#fff"
                  />
                </DeleteContainer>
                : ""}

            </IconContainer>
            <TextContainer>
              <p>{process.text || ''}</p>

            </TextContainer>
          </Title>
        )}
      </Wrapper>

      {process.sibling && (
        <SiblingArrow
          whitelist={process.text === "Match Out" || process.text === "Imposition" ? ['Barcode Stage', 'Automated Stage', 'Imposition'] : process.sibling}
          dragType={dragType}
          processID={processID}
        />
      )}
    </Container>
  )
}

export default ProcessContainer
