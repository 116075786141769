import React, { useState, useEffect, useCallback } from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import { DROP, PROCESS } from 'types'
import { LibraryItem } from 'components/composites'
import { background } from 'components/zensmart-design-system/shared/styles.js'
import { ReactComponent as QualifiersIcon } from 'static/icons/qualifiers-icon.svg'
import { ReactComponent as EditIcon } from 'static/icons/edit-icon.svg'
import { ReactComponent as CloseIcon } from 'static/icons/close.svg'
import { ReactComponent as LockIcon } from 'static/icons/lock-icon.svg'
import { ReactComponent as EmailIcon } from 'static/icons/email-icon.svg'
import { ReactComponent as CalloutIcon } from 'static/icons/callout-icon.svg'
import { ReactComponent as FieldUpdateIcon } from 'static/icons/field-update-icon.svg'
import { ReactComponent as AddCostIcon } from 'static/icons/add-cost-icon.svg'
import { color } from 'components/zensmart-design-system/shared/styles'
import { fethAllFreightGroup, productSearchRoute, getAllQuiz } from "utils/apiRoutes"
import { ZenSmartAPI } from 'utils'
import { SiblingArrow, SiblingChildArrow, DefaultPathArrow, DropZone, JoinLine } from '../primaries'

import {
  ProcessItemTextModal,
  AutomatedStageModal,
  BarcodeDetailsModal,
  EmailTriggerModal,
  FieldUpdateTriggerModal,
  CalloutTriggerModal,
  AddCostTriggerModal,
  QueueRulesModal,
  BatchRulesModal,
  MatchOutModal,
  DispatchModal,
  PickingModal,
} from '../sections'

import { getColorFromType } from 'utils'
import PreDispatchModal from '../sections/PreDispatchModal'

// width: ${props => props.children && props.children.length > 1 && !props.isOneChild ? 'calc(100% + 28px)' : '100%'}
const Container = styled.div`
  display: flex;
  align-items: flex-start;
`

const Wrapper = styled.div`
  position: relative;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  background-color: ${props => props.color};
  border-radius: 50%;

  cursor: ${props => props.cursor || 'pointer'};
  transition-duration: 0.15s;
`

const Icons = styled.div`
  z-index: 2;
  display: flex;

  position: absolute;
  top: -4px;
  right: -2px;

  & > ${IconContainer}:not(:last-of-type) {
    margin-right: 2px;
  }
`

const NumberBadge = styled.div`
  position: absolute;
  bottom: -10px;
  left: 10px;
  z-index: 2;

  background-color: ${background.default};
  border-radius: 50%;

  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
`
const ChildrenWrapper = styled.div`
  display: flex;
`

const ChildrenContainer = styled.div`
  display: flex;
  position: relative;
`

const QueueRulesIcon = styled.div`
  position: absolute;
  z-index: 3;
  top: -5px;
  left: -3px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

/**
 * Utility function for disabling react-beautiful-dnd's
 * built-in drop animations
 */
function getStyle(style, snapshot) {
  if (!snapshot.isDragging) {
    return {}
  }

  if (!snapshot.isDropAnimating) {
    return style;
  }

  return {
    ...style,
    transitionDuration: `0.001s`,
  }
}

function ProcessItem(props) {
  const { process, processID, processIndex, processNumber, editArea, productID, children = {} } = props
  const [childProcesses, setChildProcesses] = useState([])
  const [isOneChild, setIsOneChild] = useState(false)
  const [arrowHeight, setArrowHeight] = useState(0)
  const [freightGroups, setFreightGroups] = useState(null)
  const [gamificationQuiz, setGamificationQuiz] = useState([])
  const {
    dragType,
    modal,
    updateProcess,
    updateTrigger,
    matchGroupOptions,
    getMatchRange,
    onRemoveBarcode,
    scanTypesOptions,
    automatedScanTypesOptions,
    queueRules,
    setQueueRules,
    batchRules,
    setBatchRules,
    qualifiers,
    recipients,
    emailTemplates,
    removeTrigger,
    dispatchTemplatesOptions,
    batchRulesOptions,
    preDistachRules,
    setPreDistachRules,
    letterRange,
    originalDispatchRules,
    setOriginalDispatchRules,
    originalBatchRules,
    setOriginalBatchRules,
    originalQueueRules,
    setOriginalQueueRules,
    setPickingGroupOptions,
    setPickingLocations,
    pickingComponents,
    pickingLocations,
    pickingGroupOptions,
    setPickingComponents,
    matchOutRules,
    setMatchOutRules,
    originalMatchOutRules,
    setOriginalMatchOutRules,
    matchGroup, 
    setMatchGroup,
    getMatchGroup,
    forceSeparateQueues,
    updateForceSeparateQueues,

  } = editArea
  const [currentModal, setModal] = modal
  const [showQualifiersModal, setShowQualifiersModal] = useState(false)
  const [processQualifiers, setProcessQualifiers] = useState([])
  const [originalProcessQualifiers, setOriginalProcessQualifiers] = useState([])
  const [qualifierStage, setQualifierStage] = useState(0)
  const [qualifiersToRemove, setQualifiersToRemove] = useState([])

  const itemID = `<${DROP.ITEM}>${processID}`
  const containerID = `<${DROP.ACCEPT}>${processID}`

  const isLocked = !process.editable
  const isAutomated = process.type === PROCESS.TYPE.AUTOMATED
  const isBarcode = process.type === PROCESS.TYPE.BARCODE
  const isQueued = process.type === PROCESS.TYPE.QUEUED
  const isReady = process.type === PROCESS.TYPE.READY
  const isMatchOut = process.type === PROCESS.TYPE.MATCH_OUT
  const isPreDispatch = process.type === PROCESS.TYPE.PRE_DISPATCH
  const isImposition = process.type === PROCESS.TYPE.IMPOSITION
  const isDispatch = process.type === PROCESS.TYPE.DISPATCH
  const isPicking = process.type === PROCESS.TYPE.PICKING
  const isDefault = !isBarcode && !isQueued && !isPreDispatch && !isDispatch && !isAutomated

  const EditTextIcon = (isLocked && process.type !== PROCESS.TYPE.IMPOSITION) ? LockIcon : EditIcon

  const showEditTextModal = () => {
    setModal(itemID)
  }

  const handleShowQualifierModalClick = (children) => {
    const stage = children[0].props.process.stage
    setQualifierStage(stage)
    setShowQualifiersModal(true)
  }
  
  const handleShowQualifierBlockModalClick = () => {
    setQualifierStage('0')
    setShowQualifiersModal(true)
  }

  const closeModal = useCallback((evt) => {
    /**
     * Click events on the antd Modal component won't
     * work unless the event doesn't get propagated further.
     * 
     * More details on this link:
     * {@link https://github.com/ant-design/ant-design/issues/8738#issuecomment-354035083}
     * 
     **/


    if (evt?.stopPropagation) {
      evt.stopPropagation()
    }

    setModal(null)
    setShowQualifiersModal(false)
    setFreightGroups(null)
    //setOriginalQueueRules(queueRules)
    setOriginalBatchRules(batchRules)
  }, [batchRules, queueRules, setModal, setOriginalBatchRules, setOriginalQueueRules])

  const showTriggerModal = triggerName => {
    setModal(`${itemID}>>${triggerName}`)
  };

  const removeBarcode = () => {
    setChildProcesses([])
    onRemoveBarcode(process);
  }

  const IfFirstBarcode = () => {
    if (processID.includes("process,3")) {
      // if (process.text === "Imposition") {
      //   return false
      // }
      const getFirstChildren = processID.split('children.')[1][0]
      const getLastChildren = processID.split(`children.${getFirstChildren}.children.`)[1][0]
      if (getLastChildren == 0) {
        return `'process,3.children.${getFirstChildren}.children.-1'`
      }
      else {
        return false
      }
    }
    return false
  }

  useEffect(() => {
    if (isPreDispatch) {
      ZenSmartAPI.get(fethAllFreightGroup)
        .then((res) => {
          setFreightGroups && setFreightGroups(res.data.data)
        })
        .catch((res) => {
        })
    }
  }, [productID, currentModal]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(children).length) {
      const result = []
      for (const [,value] of Object.entries(children)) {
        result.push(value)
      }
      const isOneStageOneChild = result.length === 1 && result[0].length === 1
      const height = isOneStageOneChild ? '20px' : 'auto'
      setIsOneChild(isOneStageOneChild)
      setArrowHeight(height)
      setChildProcesses(result)
    }
  }, [children])

  useEffect(() => {
    if (showQualifiersModal) {
      if(process?.qualifiers){
        setProcessQualifiers(process.qualifiers.filter(q => q.stage == qualifierStage) || [])
        setOriginalProcessQualifiers(process.qualifiers.filter(q => q.stage == qualifierStage) || [])
      }else{
        setProcessQualifiers([])
        setOriginalProcessQualifiers([])
      }
    }
  }, [closeModal, process.qualifiers, qualifierStage, showQualifiersModal])

  const acceptList = ['Barcode Stage', 'Picking']

  useEffect(() => {
    if(gamificationQuiz.length === 0) {
      ZenSmartAPI.get(getAllQuiz(''))
        .then((res) => {
          setGamificationQuiz(res?.data ?? [])
        })
        .catch((res) => {
        })}
  }, [])

  return (
    <Droppable
      type={process.attachables.includes(dragType) && dragType}
      droppableId={itemID}
    >
      {provided => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          <Draggable
            draggableId={itemID}
            index={processIndex}
          >
            {(provided, snapshot) => (
              <Container
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getStyle(provided.draggableProps.style, snapshot)}
                data-with-sibling-arrow={process.sibling && 1}
                children={childProcesses}
                isOneChild={isOneChild}
              >
                {IfFirstBarcode() && (
                  <SiblingArrow
                    whitelist={['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking']}
                    dragType={dragType}
                    processID={IfFirstBarcode()}
                    ifFirstBarcode={true}
                  />
                )}
                <Wrapper>
                {process.type === 'Barcode Stage' && process.text !== 'Match In' && process.text !== 'Match Out' &&
                    <QueueRulesIcon
                      style={{background : "#1890ff"}}
                      onClick={() => handleShowQualifierBlockModalClick()}
                    >
                    <QualifiersIcon
                      width="15px"
                      height="15px"
                      fill="#fff"
                    />
                  </QueueRulesIcon>
                  }
                  <LibraryItem
                    style={{textTransform : "capitalize"}}
                    type={process.type}
                    text={process.text}
                  />

                  <NumberBadge>{processNumber}</NumberBadge>

                  <Icons>
                    {process.triggers.addCost ? (
                      <IconContainer
                        color={getColorFromType(process.type)}
                        onClick={() => showTriggerModal(PROCESS.TRIGGER.ADD_COST)}
                      >
                        <AddCostIcon width="8px" height="8px" fill="#fff" />

                        <AddCostTriggerModal
                          title={`[Add Cost] ${process.text}`}
                          visible={currentModal === `${itemID}>>${PROCESS.TRIGGER.ADD_COST}`}
                          onCancel={closeModal}
                          footer={null}
                          processID={itemID}
                          addCost={process.triggers.addCost}
                          onSave={updateTrigger}
                          removeTrigger={removeTrigger}
                        />
                      </IconContainer>
                    ) : null}

                    {process.triggers.callout ? (
                      <IconContainer
                        color={getColorFromType(process.type)}
                        onClick={() => showTriggerModal(PROCESS.TRIGGER.CALLOUT)}
                      >
                        <CalloutIcon width="8px" fill="#fff" />

                        <CalloutTriggerModal
                          title={`[Callout] ${process.text}`}
                          width={750}
                          visible={currentModal === `${itemID}>>${PROCESS.TRIGGER.CALLOUT}`}
                          onCancel={closeModal}
                          footer={null}
                          processID={itemID}
                          callout={process.triggers.callout}
                          onSave={updateTrigger}
                          removeTrigger={removeTrigger}
                          qualifiers={qualifiers}

                        />
                      </IconContainer>
                    ) : null}

                    {process.triggers.fieldUpdate ? (
                      <IconContainer
                        color={getColorFromType(process.type)}
                        onClick={() => showTriggerModal(PROCESS.TRIGGER.FIELD)}
                      >
                        <FieldUpdateIcon width="8px" fill="#fff" />

                        <FieldUpdateTriggerModal
                          title={`[Field Update] ${process.text}`}
                          visible={currentModal === `${itemID}>>${PROCESS.TRIGGER.FIELD}`}
                          onCancel={closeModal}
                          footer={null}
                          processID={itemID}
                          fieldUpdate={process.triggers.fieldUpdate}
                          onSave={updateTrigger}
                          removeTrigger={removeTrigger}
                          qualifiers={qualifiers}
                          width={700}

                        />
                      </IconContainer>
                    ) : null}

                    {process.triggers.sendEmail ? (
                      <IconContainer
                        color={getColorFromType(process.type)}
                        onClick={() => showTriggerModal(PROCESS.TRIGGER.EMAIL)}
                      >
                        <EmailIcon width="8px" fill="#fff" />

                        <EmailTriggerModal
                          title={`[Send Email] ${process.text}`}
                          width={700}
                          visible={currentModal === `${itemID}>>${PROCESS.TRIGGER.EMAIL}`}
                          onCancel={closeModal}
                          footer={null}
                          processID={itemID}
                          sendEmail={process.triggers.sendEmail}
                          onSave={updateTrigger}
                          removeTrigger={removeTrigger}
                          qualifiers={qualifiers}
                          recipients={recipients}
                          emailTemplates={emailTemplates}
                        />
                      </IconContainer>
                    ) : null}

                    <IconContainer
                      cursor={(isLocked && process.type !== PROCESS.TYPE.IMPOSITION) ? 'not-allowed' : null}
                      color={getColorFromType(process.type)}
                      onClick={() => showEditTextModal() }
                    >

                      <EditTextIcon
                        width="8px"
                        height="8px"
                        fill="#fff"
                      />

                      <AutomatedStageModal
                        gamificationQuiz={gamificationQuiz}
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isAutomated && !showQualifiersModal}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        scanTypesOptions={automatedScanTypesOptions}
                      />

                      <PreDispatchModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isPreDispatch}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        qualifiers={qualifiers}
                        preDistachRules={preDistachRules}
                        letterRange={letterRange}
                        freightGroups={freightGroups}
                        setFreightGroups={setFreightGroups}
                        setPreDistachRules={setPreDistachRules}
                        originalDispatchRules={originalDispatchRules}
                        setOriginalDispatchRules={setOriginalDispatchRules}
                      />

                      <DispatchModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isDispatch}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        gamificationQuiz={gamificationQuiz}
                        dispatchTemplatesOptions={dispatchTemplatesOptions}
                        onSave={updateProcess}
                      />

                      <ProcessItemTextModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isDefault  && !isPicking}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                      />

                      <BarcodeDetailsModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && (isBarcode || isImposition) && !isQueued && !showQualifiersModal}
                        onCancel={closeModal}
                        footer={null}
                        gamificationQuiz={gamificationQuiz}
                        newItemType={process.type}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        scanTypesOptions={scanTypesOptions}
                      />

                      <PickingModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && (isPicking) && !showQualifiersModal}
                        onCancel={closeModal}
                        footer={null}
                        newItemType={process.type}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        scanTypesOptions={scanTypesOptions}
                        pickingGroupOptions={pickingGroupOptions}
                        pickingComponents={pickingComponents}
                        pickingLocations={pickingLocations}
                        setPickingGroupOptions={setPickingGroupOptions}
                        setPickingLocations={setPickingLocations}
                        setPickingComponents={setPickingComponents}
                      />

                      <MatchOutModal
                        title={`Edit ${process.type}`}
                        visible={currentModal === itemID && isMatchOut && !isBarcode}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        matchGroupOptions={matchGroupOptions}
                        getMatchRange={getMatchRange}
                        onSave={updateProcess}
                        matchOutRules={matchOutRules}
                        setMatchOutRules={setMatchOutRules}
                        originalMatchOutRules={originalMatchOutRules}
                        setOriginalMatchOutRules={setOriginalMatchOutRules}
                        getMatchGroup={getMatchGroup}
                        matchGroup={matchGroup} 
                        setMatchGroup={setMatchGroup}
                      />

                      <QueueRulesModal
                        visible={(currentModal === itemID && isQueued) || showQualifiersModal}
                        onCancel={closeModal}
                        edit={true}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        queueRules={childProcesses.length ? processQualifiers : (process.type === PROCESS.TYPE.QUEUED ? queueRules : processQualifiers )}
                        setQueueRules={childProcesses.length ? setProcessQualifiers : (process.type === PROCESS.TYPE.QUEUED ? setQueueRules : setProcessQualifiers )}
                        stage={qualifierStage}
                        qualifiersToRemove={qualifiersToRemove}
                        setQualifiersToRemove={setQualifiersToRemove}
                        qualifiers={qualifiers}
                        originalQueueRules={childProcesses.length ? originalProcessQualifiers : originalQueueRules}
                        setOriginalQueueRules={childProcesses.length ? setOriginalProcessQualifiers : setOriginalQueueRules}
                        forceSeparateQueues={forceSeparateQueues}
                        updateForceSeparateQueues={updateForceSeparateQueues}
                      />

                      <BatchRulesModal
                        visible={currentModal === itemID && isReady}
                        onCancel={closeModal}
                        footer={null}
                        processID={itemID}
                        process={process}
                        onSave={updateProcess}
                        batchRules={batchRules}
                        setBatchRules={setBatchRules}
                        qualifiers={qualifiers}
                        originalBatchRules={originalBatchRules}
                        setOriginalBatchRules={setOriginalBatchRules}
                        batchRulesOptions={batchRulesOptions}
                        setModal={setModal}
                      />
                    </IconContainer>

                    {((isBarcode || isAutomated || isImposition || isPicking) && process.text !== 'QA Fail' && process.text !== 'Match In') &&
                      <IconContainer
                        color={color.red}
                        onClick={removeBarcode}
                      >
                        <CloseIcon
                          width="16px"
                          height="16px"
                          fill="#fff"
                        />
                      </IconContainer>
                    }
                  </Icons>
                </Wrapper>

                {/* process.sibling && (!(process.children && process.children.length) || isOneChild) */}
                {!process.hideSibling && process.sibling && (!(process.children && process.children.length)) && (
                  <SiblingArrow
                    whitelist={process.text === "Imposition" || process.text === "Picking" || process.type === "Barcode Stage" || process.type === "Automated Stage" ? ['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking'] : process.text === "Match Out" ? ['Barcode Stage', 'Automated Stage'] : process.sibling}
                    dragType={dragType}
                    processID={processID}
                  />
                )}

                {/* process.sibling && process.children && process.children.length && !isOneChild */}
                {process.sibling && process.children && process.children.length && (
                  <DefaultPathArrow
                    whitelist={process.text === "Imposition" || process.text === "Picking" || process.type === "Barcode Stage" || process.type === "Automated Stage" ? ['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking'] : process.text === "Match Out" ? ['Barcode Stage', 'Automated Stage'] : process.sibling}
                    dragType={dragType}
                    processID={processID}
                  />
                )}
              </Container>
            )}
          </Draggable>

          {process.text !== 'Ready for Release' && (
            <div style={IfFirstBarcode() ? { marginLeft: '25px' } : {}}>
              <DropZone
                whitelist={process.text === "Imposition" || process.text === "Picking" || process.type === "Barcode Stage" || process.type === "Automated Stage" ? ['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking'] : process.text === "Match Out" ? ['Barcode Stage', 'Automated Stage'] : process.sibling}
                dragType={dragType}
                processID={processID}
              />
            </div>
          )}

          {/* Child processes */}
          {
            (process.sibling && process.children && process.children.length ? <ChildrenWrapper style={{marginLeft: `${isOneChild ? '0px' : '64px'}`, flexDirection: `${isOneChild ? 'column' : 'row'}`}}>
              <SiblingChildArrow height={arrowHeight} children={childProcesses} style={{flex: '1 1 auto'}} />
              <div style={{position: 'relative', marginRight: '12px'}}>
                {/* The bottom value is half the height of a process item (28px) plus height of DropZone (18px) */}
                <div style={{position: 'absolute', right: '0', bottom: '42px', height: `${isOneChild ? '130%' : 'calc(100% - 12px)'}`, borderLeft: `2px solid ${color.nav.unselected}`}}></div>
                {childProcesses.map((children, index) => {
                  return (
                    <>
                      <div style={{display: 'flex', alignItems: 'center', marginTop: `${isOneChild ? '0px' : '1.7em'}`, marginLeft: `${isOneChild ? '32px' : '0px'}` }}>
                        {!isOneChild && <div style={{borderTop: `2px solid ${color.nav.unselected}`, width: '1.5em'}}></div>}
                        <Droppable
                          type={['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking']}
                          droppableId={`${containerID},stage-${index+1}`}
                        >
                          {provided => (
                            <ChildrenContainer
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              isFirst={index === 0}
                              count={childProcesses.length}
                              isOneChild={isOneChild}
                            >
                              <QueueRulesIcon
                                color={getColorFromType(childProcesses[index][0]?.props?.process?.type || null)}
                                onClick={() => handleShowQualifierModalClick(children)}
                              >
                                <QualifiersIcon
                                  width="15px"
                                  height="15px"
                                  fill="#fff"
                                />
                              </QueueRulesIcon>
                              {children.map(c => {
                                const id = c.props.processID
                                return (
                                  <div style={{display: 'flex'}}>
                                    {c}
                                    <SiblingArrow
                                      whitelist={process.text === "Imposition" || process.text === "Picking" || process.type === "Barcode Stage" || process.type === "Automated Stage" ? ['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking'] : process.text === "Match Out" ? ['Barcode Stage', 'Automated Stage'] : process.sibling}
                                      dragType={dragType}
                                      processID={id}
                                    />
                                  </div>
                                )
                              })}
                              {/* {children} */}
                              {provided.placeholder}
                            </ChildrenContainer>
                          )}
                        </Droppable>
                        {/* <SiblingArrow
                          whitelist={process.text === "Imposition" || process.text === "Picking" || process.type === "Barcode Stage" || process.type === "Automated Stage" ? ['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking'] : process.text === "Match Out" ? ['Barcode Stage', 'Automated Stage'] : process.sibling}
                          dragType={dragType}
                          processID={processID}
                        /> */}
                        {/* <JoinLine isOneChild={isOneChild} processID={arrowProcessID} dragType={dragType} /> */}
                        <div style={{borderTop: `2px solid ${color.nav.unselected}`, flexGrow: '1', minWidth: '12px'}}></div>
                      </div>
                      <DropZone
                        whitelist={process.text === "Imposition" || process.text === "Picking" || process.type === "Barcode Stage" || process.type === "Automated Stage" ? ['Barcode Stage', 'Automated Stage', 'Imposition', 'Picking'] : process.text === "Match Out" ? ['Barcode Stage', 'Automated Stage'] : process.sibling}
                        dragType={dragType}
                        processID={children[0].props.processID}
                        stage={index + 1}
                        root={false}
                      />
                    </>
                  )}
                )}
              </div>
            </ChildrenWrapper> : null)
          }

          {/**
            * @note 
            * Add the placeholder but put it in an element with a display
            * of _none_.
            * 
            * This is so we won't have that weird _auto-stretching_ whenever
            * a draggable item is hovering over any process item component 
            */}
          <span style={{ display: 'none' }}>{provided.placeholder}</span>
        </div>
      )}
    </Droppable>
  )
}

export default ProcessItem