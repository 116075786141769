import React from 'react'
import styled from 'styled-components'
import { color } from 'components/zensmart-design-system/shared/styles.js'
import { Droppable } from 'react-beautiful-dnd'
import { DROP } from 'types'

const ArrowWrapper = styled.div`
  height: 56px;
  width: calc(100% - 136px - 12px);
  display: flex;
  align-items: center;
  margin-right: 21px !important;
`

const Arrow = styled.div`
  position:relative;
  width: calc(100% + 24px);
  border-bottom:2px solid ${color.nav.unselected};

  &:after {
    content: '';
    width: 0; 
    height: 0; 
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid ${color.nav.unselected};
    position: absolute;
    right: -16px;
    top: -7px;
  }
`

function DefaultPathArrow(props) {
  const { whitelist, dragType, processID } = props

  return (
    <Droppable
      type={whitelist.includes(dragType) && dragType}
      droppableId={`<${DROP.SIBLING}>${processID}`}
    >
      {provided => (
          <ArrowWrapper
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <Arrow />
            {provided.placeholder}
          </ArrowWrapper>
        )
      }
    </Droppable>
  )
}

export default DefaultPathArrow