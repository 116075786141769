import getObject from 'lodash/fp/getOr'
import setObject from 'lodash/fp/set'
import generateKey from 'pages/ProductFlow/utils/generateKey'
import { ACTION, PROCESS } from 'types'
import {
  createContainer,
  createItem,
  createTrigger,
  getStage
} from './utils'
import cloneDeep from 'lodash/cloneDeep'

/**
 * Creates the transparent container process where it will only accept
 * block items as children. Also creates (1) block item as a child
 * of this transparent container.
 */
function addBlockRootSibling(processes, processPaths, values) {
  const {processPath} = processPaths
  const process = getObject(processes, processPath, processes)

  const blockProcess = createContainer({
    sibling: process.sibling.filter(type => type !== PROCESS.TYPE.BLOCK),
    variant: PROCESS.VARIANT.CONTAINER,
    type: PROCESS.TYPE.TRANSPARENT,

    accepts: [PROCESS.TYPE.BLOCK],
    children: [
      createContainer({
        variant: PROCESS.VARIANT.CONTAINER,
        type: PROCESS.TYPE.BLOCK,
        editable: values.text === 'text' ? false : true,
        accepts: [PROCESS.TYPE.BARCODE, PROCESS.TYPE.IMPOSITION],
        ...values
      })
    ],
  })

  const newProcesses = setObject([...processPath, 'sibling'], [], processes)
  newProcesses.splice(processPath[0] + 1, 0, blockProcess)

  return newProcesses
}

function addBarcodeRootSibling(processes, processPaths, values) {
  const { processPath } = processPaths
  const process = getObject(processes, processPath, processes)

  const isDispatch = type => (type === PROCESS.TYPE.DISPATCH || type === PROCESS.TYPE.PRE_DISPATCH)

  const dispatchFlag = process?.sibling?.find(isDispatch)
  const barcodeProcess = createItem({
    sibling: [
      dispatchFlag,
      PROCESS.TYPE.PRE_DISPATCH,
      PROCESS.TYPE.AUTOMATED,
      PROCESS.TYPE.BARCODE
    ],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.BARCODE,
    editable: true,
    isSaved: false,
    key: generateKey(),
    ...values,
  })
  
  const newProcesses = setObject(
    [...processPath, 'sibling'],
    process.sibling.filter(type => !isDispatch(type)),
    processes
  )
  newProcesses.splice(processPath[0] + 1, 0, barcodeProcess)
  return newProcesses
}

function addAutomatedRootSibling(processes, processPaths, values) {
  const {processPath} = processPaths
  const process = getObject(processes, processPath, processes)

  const isDispatch = type => (type === PROCESS.TYPE.DISPATCH || type === PROCESS.TYPE.PRE_DISPATCH)

  const dispatchFlag = process.sibling.find(isDispatch)

  const automatedProcess = createItem({
    sibling: [
      dispatchFlag,
      PROCESS.TYPE.AUTOMATED,
      PROCESS.TYPE.BARCODE
    ],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.AUTOMATED,
    editable: true,
    key: generateKey(),
    ...values
  })

  const newProcesses = setObject(
    [...processPath, 'sibling'],
    process.sibling.filter(type => !isDispatch(type)),
    processes
  )
  newProcesses.splice(processPath[0] + 1, 0, automatedProcess)

  return newProcesses
}

function addDispatchRootSibling(processes, processPath, values) {
  const process = getObject(processes, processPath, processes)

  const dispatchProcess = createItem({
    sibling: [],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.DISPATCH,
    text: PROCESS.TYPE.DISPATCH,
    editable: true,
    ...values
  })

  const newProcesses = setObject(
    [...processPath, 'sibling'],
    process.sibling.filter(type => type !== dispatchProcess.type),
    processes
  )
  newProcesses.splice(processPath[0] + 1, 0, dispatchProcess)

  return newProcesses
}

function addPreDispatchRootSibling(processes, processPath, values) {

  const process = getObject(processes, processPath, processes)

  const preDispatchProcess = createItem({
    freightGroup: '',
    sibling: [PROCESS.TYPE.DISPATCH],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.PRE_DISPATCH,
    ...values
  })

  const newProcesses = setObject(
    [...processPath, 'sibling'],
    process.sibling.filter(type => type !== preDispatchProcess.type),
    processes
  )
  newProcesses.splice(processPath[0] + 1, 0, preDispatchProcess);

  return newProcesses
}

function addRootSibling(processes, payload) {
  switch (payload.processType) {
    case PROCESS.TYPE.BLOCK:
      return addBlockRootSibling(processes, payload.processPaths, payload.values)
    case PROCESS.TYPE.BARCODE:
      return addBarcodeRootSibling(processes, payload.processPaths, payload.values)
    case PROCESS.TYPE.AUTOMATED:
      return addAutomatedRootSibling(processes, payload.processPaths, payload.values)
    case PROCESS.TYPE.PRE_DISPATCH:
      return addPreDispatchRootSibling(processes, payload.processPaths, payload.values)
    case PROCESS.TYPE.DISPATCH:
      return addDispatchRootSibling(processes, payload.processPaths, payload.values)
    default:
      return processes
  }
}

function addPickingSibling(processes, processPaths, values, rootChild) {
  const {processPath, childrenPath} = processPaths
  const blockProcessPath = childrenPath.length ? processPath.slice(0, processPath.length - 3): processPath.slice(0, processPath.length - 2)
  const process = getObject(processes, blockProcessPath, processes)

  values.editable = true;

  const barcodeProcess = createItem({
    sibling: [PROCESS.TYPE.BARCODE, PROCESS.TYPE.AUTOMATED],
    variant: PROCESS.VARIANT.ITEM,
    text: PROCESS.TYPE.PICKING,
    type: PROCESS.TYPE.PICKING,
    isSaved: false,
    key: generateKey(),
    ...values,
  })

  let processChildren = (processPath[0] > 3 ? [process] : (process.children ? process.children.slice() : process))
  let newProcessChildren = cloneDeep(processChildren)
  if (!childrenPath.length) {
    const processIndex = processPath[processPath.length - 1]
    if (rootChild === 'root') {
      barcodeProcess.stage = 1
      newProcessChildren[processIndex].children = []
      newProcessChildren[processIndex].children.push(barcodeProcess)
    } else if (!rootChild) {
      newProcessChildren.splice(processIndex + 1, 0, barcodeProcess)
    }
  } else {
    const processIndex = processPath[processPath.length - 2]
    if (rootChild === 'child') {
      const parentStage = getStage(childrenPath)
      const updatedChildren = newProcessChildren[processIndex].children.map(c => {
        const stage = c.stage
        if (stage > parentStage) {
          c.stage = stage + 1
        }
        return c
      })
      barcodeProcess.stage = parentStage + 1
      updatedChildren.push(barcodeProcess)
      newProcessChildren[processIndex].children = updatedChildren
    } else if (!rootChild) {
      barcodeProcess.stage = getStage(childrenPath)
      if (Array.isArray(newProcessChildren[processIndex].children)) {
        let newProcessFlow = [];
        //Added the changes for updating flow with dragging next child for children 
        //Group by stage for loop
        let groupedByStage = newProcessChildren[processIndex].children.reduce((acc, child) => {
          // Initialize the group if it doesn't exist
          if (!acc[child.stage]) {
            acc[child.stage] = [];
          }
          // Add the child to the appropriate group
          acc[child.stage].push(child);
          return acc;
        }, {});
        // Process each group
        Object.keys(groupedByStage).forEach(groupKey => {
          let group = groupedByStage[groupKey];
          if(group.length === 1 && group[0]?.stage === barcodeProcess.stage){
            newProcessFlow.push(group[0])
            newProcessFlow.push(barcodeProcess)
          }else{
            let foundKey = false;
            group.forEach((child, index) => {
              // Check if the child's key matches the key we want to filter
              if (index === childrenPath[1]+1 && barcodeProcess.stage === child.stage) {
                foundKey = true;
                barcodeProcess.number = child.number;
                newProcessFlow.push(barcodeProcess)
                // Create a new child object with the incremented number
                let updatedChild = {
                  ...child,
                  number: child.number + 1
                };
                newProcessFlow.push(updatedChild)
                foundKey = true
              }else{
                if(foundKey){
                  let updatedChild = {
                    ...child,
                    number: child.number + 1
                  };
                  newProcessFlow.push(updatedChild)
                }else{
                  newProcessFlow.push(child)
                }
              }
            });
            if(!foundKey && group[0]?.stage === barcodeProcess.stage){
              newProcessFlow.push(barcodeProcess)
            }
          }
        });
        newProcessChildren[processIndex].children = newProcessFlow;
      } else {
        newProcessChildren[processIndex].children = []
        newProcessChildren[processIndex].children.push(barcodeProcess)
      }
    }
  }

  const newProcess = processPath[0] > 3 ?
  newProcessChildren[0]  
  :
  {
    ...process,
    children: newProcessChildren
  }

  return setObject(blockProcessPath, newProcess, processes)
}


function addBarcodeSibling(processes, processPaths, values, rootChild) {
  const {processPath, childrenPath} = processPaths
  const blockProcessPath = childrenPath.length ? processPath.slice(0, processPath.length - 3): processPath.slice(0, processPath.length - 2)
  const process = getObject(processes, blockProcessPath, processes)
  const barcodeProcess = createItem({
    sibling: [PROCESS.TYPE.BARCODE, PROCESS.TYPE.AUTOMATED],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.BARCODE,
    isSaved: false,
    children : [],
    key: generateKey(),
    ...values,
  })

  let processChildren = (processPath[0] > 3 ? [process] : (process.children ? process.children.slice() : process))
  let newProcessChildren = cloneDeep(processChildren)
  if (childrenPath.length === 0) {
    const processIndex = processPath[processPath.length - 1]
    if (rootChild === 'root') {
      if (newProcessChildren[processIndex].children?.length) {
        const updatedChildren = newProcessChildren[processIndex].children.map(c => {
          c.stage = c.stage + 1
          return c
        })
        barcodeProcess.stage = 1
        updatedChildren.unshift(barcodeProcess)
        newProcessChildren[processIndex].children = updatedChildren
      } else {
        /* set stage 1 on process, push process to item children */
        barcodeProcess.stage = 1
        newProcessChildren[processIndex].children = []
        newProcessChildren[processIndex].children.push(barcodeProcess)
      }
    } else if (!rootChild) {
      barcodeProcess.stage = 0
      if(processPath[0] > 3){
        let newProcesses = cloneDeep(processes)
        newProcesses.splice(processPath[0]+1, 0, barcodeProcess);
  
        return newProcesses
      }
      newProcessChildren.splice(processIndex + 1, 0, barcodeProcess)
    }
  } else {
    const processIndex = processPath[processPath.length - 2]
    if (rootChild === 'child') {
      /**
       * If item dragged below nested child
       * get parent stage, loop over children adding 1 to each process with stage > parentStage
       * set new process stage to parentStage + 1 and push to updated children
       * update newProcessChildren
       */
      const parentStage = getStage(childrenPath)
      const updatedChildren = newProcessChildren[processIndex].children.map(c => {
        const stage = c.stage
        if (stage > parentStage) {
          c.stage = stage + 1
        }
        return c
      })
      barcodeProcess.stage = parentStage + 1
      updatedChildren.push(barcodeProcess)
      newProcessChildren[processIndex].children = updatedChildren
    } else if (!rootChild) {
      barcodeProcess.stage = getStage(childrenPath)
      if (Array.isArray(newProcessChildren[processIndex].children)) {
        let newProcessFlow = [];
        //Added the changes for updating flow with dragging next child for children 
        //Group by stage for loop
        let groupedByStage = newProcessChildren[processIndex].children.reduce((acc, child) => {
          // Initialize the group if it doesn't exist
          if (!acc[child.stage]) {
            acc[child.stage] = [];
          }
          // Add the child to the appropriate group
          acc[child.stage].push(child);
          return acc;
        }, {});

        // Process each group
        Object.keys(groupedByStage).forEach(groupKey => {
          let group = groupedByStage[groupKey];
          if(group.length === 1 && group[0]?.stage === barcodeProcess.stage){
            newProcessFlow.push(group[0])
            newProcessFlow.push(barcodeProcess)
          }else{
            let foundKey = false;
            group.forEach((child, index) => {
              // Check if the child's key matches the key we want to filter
              if (index === childrenPath[1]+1 && barcodeProcess.stage === child.stage) {
                foundKey = true;
                barcodeProcess.number = child.number;
                newProcessFlow.push(barcodeProcess)
                // Create a new child object with the incremented number
                let updatedChild = {
                  ...child,
                  number: child.number + 1
                };
                newProcessFlow.push(updatedChild)
                foundKey = true
              }else{
                if(foundKey){
                  let updatedChild = {
                    ...child,
                    number: child.number + 1
                  };
                  newProcessFlow.push(updatedChild)
                }else{
                  newProcessFlow.push(child)
                }
              }
            });
            if(!foundKey && group[0]?.stage === barcodeProcess.stage){
              newProcessFlow.push(barcodeProcess)
            }
          }
        });
        newProcessChildren[processIndex].children = newProcessFlow;
      } else {
        newProcessChildren[processIndex].children = []
        newProcessChildren[processIndex].children.push(barcodeProcess)
      }
    }
  }
  const newProcess = processPath[0] > 3 ?
  newProcessChildren[0]  
  :
  {
    ...process,
    children: newProcessChildren
  }

  return setObject(blockProcessPath, newProcess, processes)
}

function addImpositionSibling(processes, processPaths, values, rootChild) {
  const {processPath, childrenPath} = processPaths
  const blockProcessPath = childrenPath.length ? processPath.slice(0, processPath.length - 3): processPath.slice(0, processPath.length - 2)

  const process = getObject(processes, blockProcessPath, processes)

  const barcodeProcess = createItem({
    sibling: [PROCESS.TYPE.BARCODE, PROCESS.TYPE.AUTOMATED, PROCESS.TYPE.IMPOSITION],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.IMPOSITION,
    isSaved: false,
    key: generateKey(),
    ...values,
  })

  let processChildren = (processPath[0] > 3 ? [process] : (process.children ? process.children.slice() : process))
  let newProcessChildren = cloneDeep(processChildren)
  if (!childrenPath.length) {
    const processIndex = processPath[processPath.length - 1]
    if (rootChild === 'root') {
      barcodeProcess.stage = 1
      newProcessChildren[processIndex].children = []
      newProcessChildren[processIndex].children.push(barcodeProcess)
    } else if (!rootChild) {
      newProcessChildren.splice(processIndex + 1, 0, barcodeProcess)
    }
  } else {
    const processIndex = processPath[processPath.length - 2]
    if (rootChild === 'child') {
      const parentStage = getStage(childrenPath)
      const updatedChildren = newProcessChildren[processIndex].children.map(c => {
        const stage = c.stage
        if (stage > parentStage) {
          c.stage = stage + 1
        }
        return c
      })
      barcodeProcess.stage = parentStage + 1
      updatedChildren.push(barcodeProcess)
      newProcessChildren[processIndex].children = updatedChildren
    } else if (!rootChild) {
      barcodeProcess.stage = getStage(childrenPath)
      if (Array.isArray(newProcessChildren[processIndex].children)) {
        let newProcessFlow = [];
        //Added the changes for updating flow with dragging next child for children 
        //Group by stage for loop
        let groupedByStage = newProcessChildren[processIndex].children.reduce((acc, child) => {
          // Initialize the group if it doesn't exist
          if (!acc[child.stage]) {
            acc[child.stage] = [];
          }
          // Add the child to the appropriate group
          acc[child.stage].push(child);
          return acc;
        }, {});

        // Process each group
        Object.keys(groupedByStage).forEach(groupKey => {
          let group = groupedByStage[groupKey];
          if(group.length === 1 && group[0]?.stage === barcodeProcess.stage){
            newProcessFlow.push(group[0])
            newProcessFlow.push(barcodeProcess)
          }else{
            let foundKey = false;
            group.forEach((child, index) => {
              // Check if the child's key matches the key we want to filter
              if (index === childrenPath[1]+1 && barcodeProcess.stage === child.stage) {
                foundKey = true;
                barcodeProcess.number = child.number;
                newProcessFlow.push(barcodeProcess)
                // Create a new child object with the incremented number
                let updatedChild = {
                  ...child,
                  number: child.number + 1
                };
                newProcessFlow.push(updatedChild)
                foundKey = true
              }else{
                if(foundKey){
                  let updatedChild = {
                    ...child,
                    number: child.number + 1
                  };
                  newProcessFlow.push(updatedChild)
                }else{
                  newProcessFlow.push(child)
                }
              }
            });
            if(!foundKey && group[0]?.stage === barcodeProcess.stage){
              newProcessFlow.push(barcodeProcess)
            }
          }
        });
        newProcessChildren[processIndex].children = newProcessFlow;
      } else {
        newProcessChildren[processIndex].children = []
        newProcessChildren[processIndex].children.push(barcodeProcess)
      }
    }
  }

  const newProcess = processPath[0] > 3 ?
  newProcessChildren[0]  
  :
  {
    ...process,
    children: newProcessChildren
  }

  let newProcesses = [...processes];

  return setObject(blockProcessPath, newProcess, newProcesses)
}

function addAutomatedSibling(processes, processPaths, values, rootChild) {
  const {processPath, childrenPath} = processPaths
  const blockProcessPath = childrenPath.length ? processPath.slice(0, processPath.length - 3): processPath.slice(0, processPath.length - 2)
  const process = getObject(processes, blockProcessPath, processes)
  
  const automatedProcess = createItem({
    sibling: [PROCESS.TYPE.AUTOMATED, PROCESS.TYPE.BARCODE],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.AUTOMATED,
    key: generateKey(),
    ...values,
  })

  let processChildren = (processPath[0] > 3 ? [process] : (process.children ? process.children.slice() : process))
  let newProcessChildren = cloneDeep(processChildren)
  if (!childrenPath.length) {
    const processIndex = processPath[processPath.length - 1]
    if (rootChild === 'root') {
      if (newProcessChildren[processIndex].children?.length) {
        const updatedChildren = newProcessChildren[processIndex].children.map(c => {
          c.stage = c.stage + 1
          return c
        })
        automatedProcess.stage = 1
        updatedChildren.unshift(automatedProcess)
        newProcessChildren[processIndex].children = updatedChildren
      } else {
        /* set stage 1 on process, push process to item children */
        automatedProcess.stage = 1
        newProcessChildren[processIndex].children = []
        newProcessChildren[processIndex].children.push(automatedProcess)
      }
    } else if (!rootChild) {
      automatedProcess.stage = null
      newProcessChildren.splice(processIndex + 1, 0, automatedProcess)
    }
  } else {
    const processIndex = processPath[processPath.length - 2]
    if (rootChild === 'child') {
      const parentStage = getStage(childrenPath)
      const updatedChildren = newProcessChildren[processIndex].children.map(c => {
        const stage = c.stage
        if (stage > parentStage) {
          c.stage = stage + 1
        }
        return c
      })
      automatedProcess.stage = parentStage + 1
      updatedChildren.push(automatedProcess)
      newProcessChildren[processIndex].children = updatedChildren
    } else if (!rootChild) {
      automatedProcess.stage = getStage(childrenPath)
      if (Array.isArray(newProcessChildren[processIndex].children)) {
        let newProcessFlow = [];
        //Added the changes for updating flow with dragging next child for children 
        //Group by stage for loop
        let groupedByStage = newProcessChildren[processIndex].children.reduce((acc, child) => {
          // Initialize the group if it doesn't exist
          if (!acc[child.stage]) {
            acc[child.stage] = [];
          }
          // Add the child to the appropriate group
          acc[child.stage].push(child);
          return acc;
        }, {});

        // Process each group
        Object.keys(groupedByStage).forEach(groupKey => {
          let group = groupedByStage[groupKey];
          if(group.length === 1 && group[0]?.stage === automatedProcess.stage){
            newProcessFlow.push(group[0])
            newProcessFlow.push(automatedProcess)
          }else{
            let foundKey = false;
            group.forEach((child, index) => {
              // Check if the child's key matches the key we want to filter
              if (index === childrenPath[1]+1 && automatedProcess.stage === child.stage) {
                foundKey = true;
                automatedProcess.number = child.number;
                newProcessFlow.push(automatedProcess)
                // Create a new child object with the incremented number
                let updatedChild = {
                  ...child,
                  number: child.number + 1
                };
                newProcessFlow.push(updatedChild)
                foundKey = true
              }else{
                if(foundKey){
                  let updatedChild = {
                    ...child,
                    number: child.number + 1
                  };
                  newProcessFlow.push(updatedChild)
                }else{
                  newProcessFlow.push(child)
                }
              }
            });
            if(!foundKey && group[0]?.stage === automatedProcess.stage){
              newProcessFlow.push(automatedProcess)
            }
          }
        });
        newProcessChildren[processIndex].children = newProcessFlow;
      }else{
        newProcessChildren[processIndex].children = []
        newProcessChildren[processIndex].children.push(automatedProcess)
      }
    }
  }

  const newProcess = processPath[0] > 3 ?
  newProcessChildren[0]  
  :
  {
    ...process,
    children: newProcessChildren
  }

  return setObject(blockProcessPath, newProcess, processes)
}

function addSibling(processes, payload) {
  switch (payload.processType) {
    case PROCESS.TYPE.BARCODE:
      return addBarcodeSibling(processes, payload.processPaths, payload.values, payload.rootChild)
    case PROCESS.TYPE.AUTOMATED:
      return addAutomatedSibling(processes, payload.processPaths, payload.values, payload.rootChild)
    case PROCESS.TYPE.IMPOSITION:
      return addImpositionSibling(processes, payload.processPaths, payload.values, payload.rootChild)
    case PROCESS.TYPE.PICKING:
      return addPickingSibling(processes, payload.processPaths, payload.values, payload.rootChild)
    default:
      return processes
  }
}

function deleteNodeByFlag(arr, keyToDelete) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].key === keyToDelete) {
      arr.splice(i, 1); // Remove the node if the key matches
      return;
    }
    if (arr[i].children && Array.isArray(arr[i].children)) {
      deleteNodeByFlag(arr[i].children, keyToDelete); // Recursively traverse the children
      if (arr[i].children.length === 0) {
        if (arr[i].type === 'Barcode Stage') {
          delete arr[i].children; // If children array becomes empty, remove the property
        }
      }
    }
  }
}

function removeChildren(processes, payload) {
  
  const newclonedprocess = cloneDeep(processes)
  deleteNodeByFlag(newclonedprocess, payload.key)
  let newProcesses = [];
  let blockCount = 0;
  /**Clean Processes when all blocks are deleted */
  newclonedprocess.filter(process => {
    process.filteredChildren = [];

    if (process.accepts && process.accepts.length) {
      if (process.variant === PROCESS.VARIANT.CONTAINER && process.children.length) {
        process.children.forEach(child => {
          blockCount = blockCount + 1;
        })
      }

      if (process.variant === PROCESS.VARIANT.CONTAINER && !process.children.length) {
        processes[2].sibling = [PROCESS.TYPE.BLOCK];
        return false;
      }
    }

    newProcesses.push(process)

    return process;
  });

  /**Clean Processes with imposition child count requirements */
  if (blockCount < 2) {
    let cleanProcesses = [...newProcesses].filter(process => {
      if (process.accepts && process.accepts.length) {
        if (process.variant === PROCESS.VARIANT.CONTAINER && process.children.length) {
          process.children.map(child => {
            child.children = child.children.filter(item => item.text !== 'Match In');
            return child;
          })
        }
      }

      if (process.type === 'Match Out') return false;

      return process;
    });

    cleanProcesses[3].sibling = [...cleanProcesses[3].sibling, PROCESS.TYPE.BARCODE]

    newProcesses = cleanProcesses;
  }

  return newProcesses;
}

function addImpositionChild(processes, processPaths, values) {
  const {processPath } = processPaths

  const process = getObject(processes, processPath, processes)

  const impositionProcess = createItem({
    sibling: [PROCESS.TYPE.AUTOMATED],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.IMPOSITION,
    editable: false,
    text: 'Imposition',
    key: generateKey(),
    ...values
  });

  const matchInProcess = createItem({
    sibling: null,
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.BARCODE,
    editable: false,
    text: 'Match In',
  })

  const matchOutProcess = {
    ...matchInProcess,
    sibling: [],
    editable: true,
    type: PROCESS.TYPE.MATCH_OUT,
    text: PROCESS.TYPE.MATCH_OUT,
  }

  let pathInsertIndex = process.children.length

  const checkMatchIn = process.children.some(data => {
    return data.text === "Match In"
  })
  if (checkMatchIn) {
    pathInsertIndex = process.children.length - 1
  }

  const newProcessChildren = process.children.slice()
  newProcessChildren.splice(pathInsertIndex, 0, impositionProcess)

  const newProcess = {
    ...process,
    accepts: process.accepts.filter(type => type !== PROCESS.TYPE.IMPOSITION),
    children: newProcessChildren
  }

  let newProcesses = [...processes];

  return setObject(processPath, newProcess, newProcesses)
}

function impositionCounter(processes) {
  let impositionCount = 0;
  processes.filter(process => {
    process.filteredChildren = [];

    if (process.accepts && process.accepts.length) {
      if (process.variant === PROCESS.VARIANT.CONTAINER && process.children.length) {
        process.children.forEach(child => {
          child.children.forEach(item => {
            if (item.type === 'Imposition') {
              impositionCount = impositionCount + 1;
            }
          })
        })
      }
    }
    return process;
  });

  return impositionCount;
}

/**
 * There is only (1) instance where this is called and it's when
 * a user wants to add a barcode item inside a block item.
 */
function addBarcodeChild(processes, processPaths, values) {
  const {processPath} = processPaths
  const process = getObject(processes, processPath, processes)

  /**
   * By default set the index where we'll insert the barcode child item
   * into as the very last item of the current process' array of children.
   */
  let pathInsertIndex = process.children.length

  /**
   * If the current process that we're adding the barcode item child into
   * is a block with a "Match In" item already in it, then make the insert
   * index be the 2ND to the last item from the process' children array.
   */

  const checkMatchIn = process.children.some(data => {
    return data.text === "Match In"
  })

  if (checkMatchIn) {
    pathInsertIndex = process.children.length - 1
  }

  const barcodeProcess = createItem({
    sibling: [PROCESS.TYPE.BARCODE, PROCESS.TYPE.AUTOMATED],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.BARCODE,
    key: generateKey(),
    ...values,
  })

  const newProcessChildren = process.children.slice()

  newProcessChildren.splice(pathInsertIndex, 0, barcodeProcess)

  const newProcess = {
    ...process,
    accepts: process.accepts.filter(type => type !== PROCESS.TYPE.BARCODE),
    children: newProcessChildren
  }

  return setObject(processPath, newProcess, processes)
}

function addBlockChild(processes, processPaths, values) {
  const { processPath } = processPaths
  const process = getObject(processes, processPath, processes)

  const matchInProcess = createItem({
    sibling: null,
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.BARCODE,
    editable: false,
    text: 'Match In',
  })

  /**
   * When we create a new block child item we assume that there
   * is always at least (1) Block item inside the current process
   * that we're adding the blocks into due to `addBlockRootSibling`.
   *
   * This means we should also add a "Match In" barcode item automatically
   * inside each new block item being added.
   */
  const blockProcess = createContainer({
    sibling: null,
    variant: PROCESS.VARIANT.CONTAINER,
    type: PROCESS.TYPE.BLOCK,
    editable: values.text === 'text' ? false : true,
    ...values,

    accepts: [PROCESS.TYPE.BARCODE, PROCESS.TYPE.IMPOSITION],
    children: [matchInProcess]
  })

  const newProcess = {
    ...process,
    children: [...process.children, blockProcess]
  }

  /**
   * Once we have described to add the new block item(s) inside the next
   * process state, at this point we should now check to see if we
   * should add in the "Match Out" barcode item as a sibling next
   * to the blocks item container process.
   *
   * We do this by checking if the number of block items currently on
   * the new process state ONLY has (2) block items in it.
   */
  if (newProcess.children.length > 1) {
    const newProcesses = processes.slice()

    const blockContainerIndex = processPath[0]

    const matchOutProcess = {
      ...matchInProcess,
      sibling: newProcess.sibling.slice(),
      editable: true,
      type: PROCESS.TYPE.MATCH_OUT,
      text: PROCESS.TYPE.MATCH_OUT,
    }

    /**
     * Remove the blocks item container's sibling since we've
     * moved it to the match out item already.
     */
    newProcess.sibling = []

    let hasMatchOut = false;
    newProcesses.forEach(process => {
      if (process.text === 'Match Out') hasMatchOut = true;
    })

    if (!hasMatchOut) {
      newProcesses.splice(blockContainerIndex + 1, 0, matchOutProcess)
    }

    newProcesses.map(process => {

      if (process.accepts && process.accepts.length) {
        if (process.variant === PROCESS.VARIANT.CONTAINER && process.children.length) {
            process.children.map(child => {
            let doesMatchExist = child.children.filter(item => item.text === 'Match In');
            if(doesMatchExist.length === 0){
              child.children = [...child.children, matchInProcess]
            }
            return child
          })
        }
      }
      return process;
    })

    return setObject(processPath, newProcess, newProcesses)
  }

  return setObject(processPath, newProcess, processes)
}

/**
 * There is only (1) instance where this is called and it's when
 * a user wants to add a barcode item inside a block item.
 */
function addPickingChild(processes, processPaths, values) {
  const { processPath } = processPaths
  const process = getObject(processes, processPath, processes)
  /**
   * By default set the index where we'll insert the barcode child item
   * into as the very last item of the current process' array of children.
   */
  let pathInsertIndex = process.children.length

  /**
   * If the current process that we're adding the barcode item child into
   * is a block with a "Match In" item already in it, then make the insert
   * index be the 2ND to the last item from the process' children array.
   */

  const checkMatchIn = process.children.some(data => {
    return data.text === "Match In"
  })

  if (checkMatchIn) {
    pathInsertIndex = process.children.length - 1
  }

  values.editable = true;

  const barcodeProcess = createItem({
    sibling: [PROCESS.TYPE.BARCODE, PROCESS.TYPE.AUTOMATED],
    variant: PROCESS.VARIANT.ITEM,
    text: PROCESS.TYPE.PICKING,
    type: PROCESS.TYPE.PICKING,
    isSaved: false,
    key: generateKey(),
    ...values,
  })

  const newProcessChildren = process.children.slice()

  newProcessChildren.splice(pathInsertIndex, 0, barcodeProcess)

  const newProcess = {
    ...process,
    accepts: process.accepts.filter(type => type !== PROCESS.TYPE.BARCODE),
    children: newProcessChildren
  }

  return setObject(processPath, newProcess, processes)
}

/**
 * There is only (1) instance where this is called and it's when
 * a user wants to add a barcode item inside a block item.
 */
function addAutomatedChild(processes, processPaths, values) {
  const {processPath} = processPaths
  const process = getObject(processes, processPath, processes)

  /**
   * By default set the index where we'll insert the barcode child item
   * into as the very last item of the current process' array of children.
   */
  let pathInsertIndex = process.children.length

  /**
   * If the current process that we're adding the barcode item child into
   * is a block with a "Match In" item already in it, then make the insert
   * index be the 2ND to the last item from the process' children array.
   */

  const checkMatchIn = process.children.some(data => {
    return data.text === "Match In"
  })

  if (checkMatchIn) {
    pathInsertIndex = process.children.length - 1
  }

  const barcodeProcess = createItem({
    sibling: [PROCESS.TYPE.BARCODE, PROCESS.TYPE.AUTOMATED],
    variant: PROCESS.VARIANT.ITEM,
    type: PROCESS.TYPE.AUTOMATED,
    key: generateKey(),
    ...values,
  })

  const newProcessChildren = process.children.slice()

  newProcessChildren.splice(pathInsertIndex, 0, barcodeProcess)

  const newProcess = {
    ...process,
    accepts: process.accepts.filter(type => type !== PROCESS.TYPE.BARCODE),
    children: newProcessChildren
  }

  return setObject(processPath, newProcess, processes)
}

function addChildren(processes, payload) {
  switch (payload.processType) {
    case PROCESS.TYPE.IMPOSITION:
      return addImpositionChild(processes, payload.processPaths, payload.values)
    case PROCESS.TYPE.BARCODE:
      return addBarcodeChild(processes, payload.processPaths, payload.values)
    case PROCESS.TYPE.AUTOMATED:
      return addAutomatedChild(processes, payload.processPaths, payload.values)
    case PROCESS.TYPE.BLOCK:
      return addBlockChild(processes, payload.processPaths, payload.values)
    case PROCESS.TYPE.PICKING:
      return addPickingChild(processes, payload.processPaths, payload.values)
    default:
      return processes
  }
}

function addTrigger(processes, payload) {
  const {processPath} = payload.processPaths
  const trigger = createTrigger(payload.processType)
  let newProcessPath = processPath[0] > 3 ? [processPath[0]] : processPath
  const process = getObject(processes, newProcessPath, processes)
  const newProcess = {
    ...process,
    attachables: process.attachables.filter(type => type !== payload.processType),
    triggers: {
      ...process.triggers,
      [payload.processType]: {
        ...trigger,
        ...payload.values
      }
    }
  }

  return setObject(newProcessPath, newProcess, processes)
}

function updateProcessData(processes, payload) {
  const { processPath, qualifiers, toRemove, child, newProcessData } = payload
  let  newProcessPath = child ? processPath.slice(0, -1) : processPath
  newProcessPath = newProcessPath[0] > 3 ? [newProcessPath[0]] : newProcessPath
  const process = getObject(processes, newProcessPath, processes)
  let newProcess
  if (qualifiers) {
    if (process.qualifiers?.length) {
      let q = [...process.qualifiers, ...newProcessData]
      if (toRemove.length) {
        q = q.filter(x => !toRemove.includes(x))
      }
      process.qualifiers = [...new Set(q)]
    } else {
      process.qualifiers = [...newProcessData]
    }

    newProcess = {
      ...process
    }
  } else {
    if (child) {
      const children = process.children.map(c => {
        if (c.key === child) {
          const ans = {
            ...c,
            ...newProcessData
          }
          return ans
        }
        return c
      })

      process.children = []
      process.children = children
      newProcess = {...process}
    } else {
      newProcess = {
        ...process,
        ...newProcessData
      }
    }
  }
  return setObject(newProcessPath, newProcess, processes)
}

function updateTriggerData(processes, payload) {
  const { processPath, triggerName, ...newTriggerData } = payload
  let newProcessPath = processPath[0] > 3 ? [processPath[0]] : processPath
  const process = getObject(processes, newProcessPath, processes)

  const newProcess = {
    ...process,
    triggers: {
      ...process.triggers,
      [triggerName]: { ...newTriggerData }
    }
  }

  return setObject(newProcessPath, newProcess, processes)
}

function removeTriggerData(processes, payload) {
  const { processPath, triggerName } = payload
  let newProcessPath = processPath[0] > 3 ? [processPath[0]] : processPath
  const process = getObject(processes, newProcessPath, processes)

  const newProcess = {
    ...process,
    attachables: [...process.attachables, triggerName],
    triggers: {
      ...process.triggers
    }
  }

  delete newProcess.triggers[triggerName]

  return setObject(newProcessPath, newProcess, processes)
}

function processesReducer(processes, action) {
  switch (action.type) {
    case ACTION.ADD_ROOT_SIBLING:
      return addRootSibling(processes, action.payload)
    case ACTION.ADD_SIBLING:
      return addSibling(processes, action.payload)
    case ACTION.ADD_CHILDREN:
      return addChildren(processes, action.payload)
    case ACTION.ADD_TRIGGER:
      return addTrigger(processes, action.payload)
    case ACTION.SET_PRODUCT_PROCESSES:
      if (action.payload[3].type === "transparent") {
        const newChildren = action.payload[3].children.map((data, key) => {
          const newChildrenKey = data.children.map((item, key) => {
            if (!item.key) {
              item.key = generateKey()
            }
            return item
          })
          data.children = newChildrenKey
          return data
        })
        action.payload[3].children = newChildren
      }
      return action.payload
    case ACTION.UPDATE_PROCESS_DATA:
      return updateProcessData(processes, action.payload)
    case ACTION.UPDATE_TRIGGER_DATA:
      return updateTriggerData(processes, action.payload)
    case ACTION.REMOVE_TRIGGER_DATA:
      return removeTriggerData(processes, action.payload)
    case ACTION.REMOVE_CHILDREN:
      return removeChildren(processes, action.payload)
    default:
      return processes
  }
}

export default processesReducer
